import React, { useState, useEffect, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  
  
  const [content, changeContent] = useState()

  useEffect(()=>{
    const urlToBlock = window.location.search.replace('?', '')

    if (urlToBlock !== "loading"){
      changeContent(<Fragment>
      <p>
        The URL:
      </p>     
      <p>
        <strong>
          {urlToBlock}
        </strong>
      </p>     
      <p>
        has been blocked by the CyberSift Protect browser plugin
      </p>
      </Fragment>)
    } else {
      changeContent(<Fragment>
        <p>
          CyberSift Protect browser plugin is checking this URL...
        </p>        
        </Fragment>)
    }
  },[])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
               {content} 
      </header>
    </div>
  );
}

export default App;
